@include foundation-breadcrumbs;

.breadcrumbs {
    a {
        &:hover {
            color: $anchor-color-hover;
        }
    }
}

.breadcrumbs--list {
    li:not(:last-child) {
        &::after {
            content: '|'
        }
    }
}
