@include foundation-visibility-classes;
@include foundation-float-classes;

/// Responsive float classes
@include -zf-each-breakpoint {
    .#{$-zf-size}-float-left {
        float: left;
    }
    .#{$-zf-size}-float-right {
        float: right;
    }
    .#{$-zf-size}-float-center {
        float: none;
        margin: 0 auto;
    }
    .#{$-zf-size}-float-none {
        float: none;
    }
}