.icon {
    display: inline-block;
    vertical-align: bottom;
}

.icon--middle  {
    vertical-align: middle!important;
}

.icon--baseline  {
    vertical-align: baseline!important;
}

.icon--rotate-90-right {
    transform: rotate(90deg);
}

.icon--rotate-90-left {
    transform: rotate(-90deg);
}
