$class-colors: (
    'primary': $primary-color,
    'primary-light': lighten($primary-color, 50%),
    'secondary': $secondary-color,
    'secondary-light': lighten($secondary-color, 50%),
    'alert': $alert-color,
    'success': $success-color,
    'warning': $warning-color,
    'black': $black,
    'white': $white,
    'dark-gray': $dark-gray,
    'medium-gray': $medium-gray,
    'light-gray': $light-gray,
    'lightest-gray': $lightest-gray,
    'body': $body-background,
);

@each $color, $value in $class-colors {
    .color-txt-#{$color}.color-txt-#{$color}.color-txt-#{$color} {
        color: $value;

        a, li, h1, h2, h3, h4, h5 {
            color: $value;
        }
    }
    .color-bg-#{$color} {
        background-color: $value;
    }
    .color-fill-#{$color} {
        fill: $value;
    }
    .color-stroke-#{$color} {
        stroke: $value;
    }
}