// ------------------------------------------------------------
//
// Mixins
//
// ------------------------------------------------------------


// ------------------------------------------------------------
// Helpers
// ------------------------------------------------------------

// Trigger graphic acceleration
@mixin trigger3d {
    transform: translateZ(0);
}


@mixin link-active-styles {
    &:hover, &:focus, &:active {
        @content;
    }
}

// Link hover styles
@mixin globalSpacing($rule, $modifier: 1) {
    @if unitless($modifier) == false {
      @warn "Use number for modifier";
    }

    @each $breakpoint, $gutter in $grid-column-gutter {
        @include breakpoint($breakpoint) {
            #{$rule}: (map-get($grid-column-gutter, $breakpoint) / 2) * $modifier;
        }
    }
}

// Selection styles
@mixin selection() {
    ::selection {
      @content;
    }
    ::-moz-selection {
      @content;
    }
}

// -------------------------------------------------------
// List styles
// -------------------------------------------------------

// Mixins for list style types
@mixin unordered {
     list-style-position: outside;
     list-style-type: disc;
}

@mixin unordered-inside {
     list-style-position: inside;
     list-style-type: disc;
}

@mixin ordered {
    list-style-position: outside;
    list-style-type: decimal;
}

@mixin ordered-inside {
    list-style-position: inside;
    list-style-type: decimal;
}

@mixin nobullet {
    list-style-type: none;
}

// -------------------------------------------------------
// Aspect ratio container
// -------------------------------------------------------

@mixin ar-container($ratioX:16, $ratioY:9, $container:'>*', $base: true, $fill: (0 0 0 0)) {
    @if ($base == true) {
        position: relative;
        overflow: hidden;

        &::after {
            content: '';
            display: block;
            height: 0;
            width: 100%;
            /* 16:9 = 56.25% = calc(9 / 16 * 100%) */
            padding-bottom: $ratioY / $ratioX * 100%;
        }

        & #{$container} {
            position: absolute;
            display: block;
            top: nth($fill, 1);
            left: nth($fill, 2);
            bottom: nth($fill, 3);
            right: nth($fill, 4);
        }
    } @else {
        &::after {
            padding-bottom: $ratioY / $ratioX * 100%;
        }
    }
}

// -------------------------------------------------------
// Break content from container horizontally
// -------------------------------------------------------
@mixin break-container() {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

// -------------------------------------------------------
// Make columns in row equal
// -------------------------------------------------------
@mixin equal-columns($child) {
    display: flex;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-direction: row;
    flex-direction: row;

    .no-flexbox & {
        display: block;
    }

    #{$child} {
        min-height: 100%;
        flex: 0 0 auto;
    }
}

// -------------------------------------------------------
// Align things vertically (http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/)
// -------------------------------------------------------

// first option
@mixin vertical-align {
  position: relative;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

// second option (if you know height)
@mixin vertical-center-container($y) {
  height          : $y;

  &::before {
    content: '';
    height        : $y;
    display       : inline-block;
    zoom          : 1;
    *display      : inline;
    vertical-align: middle;
  }
}

@mixin vertical-center-item() {
  display: inline-block;
  zoom: 1; *display: inline; vertical-align: middle;
}


// -------------------------------------------------------
// Retina images
// -------------------------------------------------------

// Usage: Send path, image name, and width and height.
// Name retina images [name]2x.

@mixin image-2x($image, $width, $height) {
    @media (min--moz-device-pixel-ratio: 1.3),
            (-o-min-device-pixel-ratio: 2.6/2),
            (-webkit-min-device-pixel-ratio: 1.3),
            (min-device-pixel-ratio: 1.3),
            (min-resolution: 1.3dppx) {
        /* on retina, use image that's scaled by 2 */
        background-image: url($image);
        background-size: $width $height;
    }
}

// -------------------------------------------------------
// HEX to RGB
// -------------------------------------------------------

// http://codepen.io/sturobson/pen/hdKyv
@mixin rgba($color, $value) {
    background-color: $color;
    background-color: rgba($color, $value);
}

// -------------------------------------------------------
// Effects
// -------------------------------------------------------

// Magic parallax mixins
$parallax-perspective : 1 !default;
$parallax-element     : "body" !default;
$parallax-ios         : true !default;

@mixin parallax-init(
    $perspective  : $parallax-perspective,
    $element      : $parallax-element,
    $parallax-ios : $parallax-ios
    ) {
    @if $element == "body" {
        html, body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
        }
    }
    #{$element} {
        overflow: auto;
        perspective: $perspective * 1px;
        transform-style: preserve-3d;
        // Allows for smooth scrolling but disables parallax effects.
        @if $parallax-ios == false {
        -webkit-overflow-scrolling: touch;
        }
        // Preserve 3D
        &, * {
        transform-style: preserve-3d;
        }
    }
}

@mixin parallax(
    $distance    : 0,
    $perspective : $parallax-perspective
    ) {
    transform:
        translateZ($distance * $perspective * 1px)
        scale(abs($distance - 1))
    ;
    z-index: $distance * 1000;
}

// End of magic parallax mixins

// -------------------------------------------------------
// Burger
// -------------------------------------------------------
// Burger parts
//
// (---) top    -> &:before
// [---] middle -> &
// (---) bottom -> &:after


// Burger
@mixin burger($width: 30px, $height: 5px, $gutter: 3px, $color: #000, $border-radius: 0, $transition-duration: .3s) {
    $burger-height: $height !global;
    $burger-gutter: $gutter !global;

    position: relative;
    margin-top: $height + $gutter;
    margin-bottom: $height + $gutter;

    &, &:before, &:after {
        display: block;
        width: $width;
        height: $height;
        background-color: $color;
        @if $border-radius != 0 {
            border-radius: $border-radius;
        }

        -webkit-transition-property: background-color, -webkit-transform;
        -moz-transition-property: background-color, -moz-transform;
        -o-transition-property: background-color, -o-transform;
        transition-property: background-color, transform;

        -webkit-transition-duration: $transition-duration;
        -moz-transition-duration: $transition-duration;
        -o-transition-duration: $transition-duration;
        transition-duration: $transition-duration;
    }

    &:before, &:after {
        position: absolute;
        content: "";
    }

    &:before {
        top: -($height + $gutter);
    }

    &:after {
        top: $height + $gutter;
    }
}


// Select parts of the burger
@mixin burger-parts {
    &, &:before, &:after {
        @content;
    }
}

@mixin burger-top {
    &:before {
        @content;
    }
}

@mixin burger-middle {
    & {
        @content;
    }
}

@mixin burger-bottom {
    &:after {
        @content;
    }
}

// -------------------------------------------------------
// Chevron
// -------------------------------------------------------
@mixin css-chevron($size: 6px, $thickness: 1px, $color: $primary-color, $output: 'inline', $direction: 'down' ){
    $border-dir1: null;
    $border-dir2: null;

    @if ($direction == 'down' or $direction == 'bottom') {
        $border-dir1: 'right';
        $border-dir2: 'bottom';
    } @elseif ($direction == 'up' or $direction == 'top') {
        $border-dir1: 'left';
        $border-dir2: 'top';
    } @elseif ($direction == 'left') {
        $border-dir1: 'left';
        $border-dir2: 'bottom';
    } @else {
        $border-dir1: 'right';
        $border-dir2: 'top';
    }

    position: absolute;
    margin: (-1 * $size) auto 0;
    top: 50%;
    width: $size;
    height: $size;
    transform: rotate(45deg);
    border-#{$border-dir1}: $thickness solid $color;
    border-#{$border-dir2}: $thickness solid $color;
    transition: border-color $global-transition, width $global-transition, height $global-transition;
}

// -------------------------------------------------------
// SVG
// -------------------------------------------------------

//
//  Function to create an optimized svg url
//
@function svg-url($svg){
    //
    //  Add missing namespace
    //
    @if not str-index($svg,xmlns) {
        $svg: str-replace($svg, '<svg','<svg xmlns="http://www.w3.org/2000/svg"');
    }
    //
    //  Chunk up string in order to avoid
    //  "stack level too deep" error
    //
    $encoded:'';
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($svg)/$slice);
    @for $i from 1 through $loops {
        $chunk: str-slice($svg, $index, $index + $slice - 1);
        //
        //   Encode
        //
        $chunk: str-replace($chunk,'"', '\'');
        $chunk: str-replace($chunk,'%', '%25');
        $chunk: str-replace($chunk,'&', '%26');
        $chunk: str-replace($chunk,'#', '%23');
        $chunk: str-replace($chunk,'{', '%7B');
        $chunk: str-replace($chunk,'}', '%7D');
        $chunk: str-replace($chunk,'<', '%3C');
        $chunk: str-replace($chunk,'>', '%3E');

        //
        //    The maybe list
        //
        //    Keep size and compile time down
        //    ... only add on documented fail
        //
        //  $chunk: str-replace($chunk, '|', '%7C');
        //  $chunk: str-replace($chunk, '[', '%5B');
        //  $chunk: str-replace($chunk, ']', '%5D');
        //  $chunk: str-replace($chunk, '^', '%5E');
        //  $chunk: str-replace($chunk, '`', '%60');
        //  $chunk: str-replace($chunk, ';', '%3B');
        //  $chunk: str-replace($chunk, '?', '%3F');
        //  $chunk: str-replace($chunk, ':', '%3A');
        //  $chunk: str-replace($chunk, '@', '%40');
        //  $chunk: str-replace($chunk, '=', '%3D');

        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }
    @return url("data:image/svg+xml,#{$encoded}");
}

//  Background svg mixin
@mixin background-svg($svg){
    background-image: svg-url($svg);
}
//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @return if($index,
        str-slice($string, 1, $index - 1) + $replace +
        str-replace(str-slice($string, $index +
        str-length($search)), $search, $replace),
        $string);
}

@mixin bg-fade($height: 150px, $deg: 6, $bg:rgba(0, 0, 0, 0.5) ) {
    background-image: linear-gradient(#{$deg}deg, #{$bg}, transparent $height);
}

@mixin highlight-text($bg: $white, $clr: $black, $lh: 1.5, $width:6px, $include-transition: true, $hover: '&:hover') {
    display: inline;
    position: relative;
    line-height: $lh;
    left: $width;
    background: $bg;
    color: $clr;
    box-shadow:
            $width 0 0 $bg,
        ($width * -1) 0 0 $bg,
        0 ($width * 1/3) 0 $bg,
        0 ($width * -1/3) 0 $bg,
        $width ($width * -1/3) 0 $bg,
        ($width * -1) ($width * -1/3) 0 $bg,
        $width ($width * 1/3) 0 $bg,
        ($width * -1) ($width * 1/3) 0 $bg;
    -webkit-box-decoration-break: clone;
    -ms-box-decoration-break: clone;
    -o-box-decoration-break: clone;
    box-decoration-break: clone;

    @if ($include-transition == true) {
        transition: background $global-transition, color $global-transition, box-shadow $global-transition;
    }

    @if ($hover != false) {
        #{$hover} {
            @include highlight-text-hover($clr, $bg);
        }
    }
}

@mixin highlight-text-hover($bg: $black, $clr: $white) {
    background-color: $bg;
    box-shadow:
        6px 0 0 $bg,
        -6px 0 0 $bg,
        0 2px 0 $bg,
        0 -2px 0 $bg,
        6px -2px 0 $bg,
        -6px -2px 0 $bg,
        6px 2px 0 $bg,
        -6px 2px 0 $bg;
    color: $clr;
}