footer {
    background: $primary-color;
    
    h3 {
        color: $black;
        margin: rem-calc(40) 0 rem-calc(40) 1.25rem;
        font-size: rem-calc(21);
    }
    
    ul {
        display: inline-block;
        margin-bottom: rem-calc(40);
        
        li {
            font-size: rem-calc(16);
            font-weight: bold;
        }
    }
}



