body {
    background: #000;
    background-image: url(#{$img-url}/bg_pattern.jpg);
}

p, span {
    font-size: rem-calc(18);
}

.row--extended {
    max-width: rem-calc(1600);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}


.row--padding {
    padding-top: rem-calc(100);
    padding-bottom: rem-calc(100);
}

.row--margin {
    margin-top: rem-calc(100);
    margin-bottom: rem-calc(100);
}

.row--margin-half {
    margin-top: rem-calc(50);
    margin-bottom: rem-calc(50);
}

.row--padding-half {
    padding-top: rem-calc(100)/2;
    padding-bottom: rem-calc(100)/2;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.margin-top-0 {
    margin-top: 0;
}

.arrow {
    display: inline-block;
        font-size: rem-calc(41);
}

.line {
    background: url(#{$img-url}/line.png);
    background-size: cover;
    width: rem-calc(3);
    display: inline-block;
}

.line--short {
    height: rem-calc(69);
}

.line--mobile {
    display: none;
}

.line--long {
    height: rem-calc(370);

    @include breakpoint(large down) {
        height: rem-calc(200);
    }
}


a:hover {
    .arrow {
        animation-duration : 0.7s;
        animation-iteration-count: 3;
        animation-direction: alternate;
        
        &.arrow--right {
            animation-name: bounce--right;
        }
        
        &.arrow--down {
            animation-name: bounce--down;
        }
    }
}


.border--btn {
    padding: rem-calc(13) rem-calc(15);
    border: 2px solid $primary-color;
    display: inline-block;
    
    .button {
        margin-bottom: 0;
        box-shadow: 0px 8px 4px -1px rgba(0,0,0,0.5);
    }
}

.arrow-box {
    display: inline-block;
}


.hide-for-medium-up {
    display: none !important;
}

.hide-for-medium-down {
    display: block !important;
}



@include breakpoint(1008 down) {
    .hide-for-medium-up {
        display: block !important;
    }
    .hide-for-medium-down {
        display: none !important;
    }
}

.error-block {
    padding-top: rem-calc(100);
}

.error {
    font-size: rem-calc(36);
    color: $primary-color;
}

div:focus, section:focus {
    outline: none;
}


