.hidden {
    display: none;
}

.transparent {
    opacity: 0;
}

.clearfix:before,
.clearfix:after {
    content:"";
    display:table;
}
.clearfix:after {
    clear:both;
}
.clearfix {
    clear: both;
    zoom:1; /* For IE 6/7 (trigger hasLayout) */
}

.no-padding {
    padding: 0!important;
}

.no-margin {
    margin: 0!important;
}

.vertical-align {
    @include vertical-align();
}

.relative {
    position: relative;
}

.iblock {
    display: inline-block;

    &.top {
        vertical-align: top;
    }

    &.bottom {
        vertical-align: bottom;
    }

    &.baseline {
        vertical-align: baseline;
    }

    &.middle {
        vertical-align: middle;
    }
}

.cursor-pointer,
.cursor-pointer * {
    cursor: pointer;
}

hr.clearfix {
    padding: 0;
    margin: 0;
    width: 100%;
    border: none;
}

.row--equal-columns {
    @include equal-columns('.column');
}

.bg-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.bg-contain {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.break-container {
    @include break-container();

    .column > & {
        @each $gutter-break, $gutter-width in $grid-column-gutter {
            @include breakpoint(#{$gutter-break}) {
                padding: 0 rem-calc($gutter-width / 2);
            }
        }
    }
}

.flex {
    display: flex;
    
    
}

.align--center {
    align-items: center;
}

.position-absolute {
    position: absolute;
}

.position-relative {
    position: relative;
}