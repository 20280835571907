.about-us {
    
    .row--extended {
        padding-right: 0;
        margin-right: 0;
        max-width: none;
    }
    
    .row {
        max-width: none;
    }
    
    .about-us__header {
        justify-content: space-evenly;
        align-items: center;
        
        img {
            max-width: 100%;
        } 
        
        .blazon {
            width: 125px;
        }
    }
    
    .about-us__footer {
        margin-top: rem-calc(100);
        img {
            max-width: 100%;
        }  
    }
    
    .about-us__text {
        max-width: 800px;
        
        
    }
    
    .media-object-section {
        flex: 1 1 auto;
    }
    
    @include breakpoint(1800 down) {
        .large-11 {
            width: 83.33333%;
        }
    }
    
    @include breakpoint(1024 down) {
        .large-11 {
            width: 93.33333%;
        }
    }
    
}

.pattern {
    background: #eeeae4;
    z-index: 0;
    
    h2 {
        color: #554936;
        margin-top: rem-calc(50);
        margin-bottom: rem-calc(50);
    }
    
    p {
        color: #8e887e;
    }
}



#jr {
    z-index: 9;
    margin-top: -40px;
    margin-left: 110px;
}

#jr__bg {
    z-index: 5;
    left: 140px;    
    margin-top: -90px;
    padding-left: 0;
    
    img {
        width: 95%;
    }
}

@include breakpoint(1300 down) {
    #jr {
        margin-left: 20px;
    }
    
    #jr__bg {
        left: -90px;
    }
}

@include breakpoint(1150 down) {
    #jr {
        img {
            width: rem-calc(350)
        }
    }
    
    #jr__bg {
        
        left: -60px;
            
        img {
            width: rem-calc(390)  
        }   
    }
}

@include breakpoint(870 down) {
    .about-us {
        .media-object {
            flex-direction: column;
        }
        
        .about-us__text {
            margin-top: $global-margin;
            display: inline-block;
        }
    }
    
    #jr {
        img {
            margin-left: 110px;
        }
    }
    
    #jr__bg {
        
        left: 0;   
    }
}

@include breakpoint(560 down) {
    #jr {
        img {
            margin-left: 0;
        }
    }
    
    #jr__bg { 
        left: -110px;   
    }
}

@include breakpoint(450 down) {
    .pattern {
        width: 100%;
    }
    
    #jr {
        margin-left: 0;
        
        img {
            width: rem-calc(300);
        }
    }
    
    #jr__bg { 
        
        img {
            width: rem-calc(340);
        }
    }
}
