.products {
    .flex {
        @include breakpoint(large down) {
            display: block;
        }
    }
}

.product {
    
    
    &:first-child {
        padding-right: 0;
    }

    &:last-child {
        padding-left: 0;
    }
    
    &.product--reverse {
        flex-direction: row-reverse;
    }
    
    .product__box--left {
        margin-right: 0;
        padding-right: 0;

        .underline {
            transform: rotate(180deg);
        }
    }
    
    .product__box--right {
        margin-left: 0;
        padding-left: 0;
        

    }
    
    @include breakpoint(1024 down) {
        &.product--reverse {
            flex-direction: row;
        }
    }
    
    .product__box--left {
        margin-right: 0;
        padding-right: 0;

        .underline {
            transform: none;
        }
    }

  
}

.underline {
    width: 110%;
    margin: rem-calc(30) 0;
}

.product__img-box {
    z-index:10;
    
    &--mobile {
        display: none;
        text-align: center;
    }
    
    img {
        width: 100%;
    }
    
    @include breakpoint(small-land down) {
        display: none;
            
        &--mobile {
            display: block;
            margin-bottom: rem-calc(25);
            
            img {
                width: 80%;
            }
        }
    }
    
    
}

.product__text-box {
    
    h3 {
        &.small {
            font-size: rem-calc(33);
        }
    }
    
    .media-object {
        align-items: center;
        
        img {
            height: rem-calc(50);
        }
    }

    .arrow {
        margin-top: rem-calc(-20);
    }

}


.row--center {
    float: none;
    clear: both;
    margin: 0 auto;
}
.offers {
    h2 {
        margin-bottom: rem-calc(30);
        
    }
}

.parts {
    color: $white;
    
    h3 {
        font-size: 2rem;
        margin-bottom: 0;
    }
    .desc {
        margin-bottom: rem-calc(20);
        margin-top: 0;
    }
    
    li {
        font-size: rem-calc(21);
        color: $primary-color;
        margin: rem-calc(15) 0;
    }
}

