$overwrite-family: 'Libre Franklin' !default;
$overwrite-family2: $overwrite-family !default;


@mixin font-styles() {
    .wf-active & {
        @content;
    }
}

h1,h2,h3,h4,h5 {
    font-weight: bold;
}

%primary-font,
body, button, input, optgroup, select, textarea, h4, h5, h6,
.altfont.altfont {
    font-family: $body-font-family;

    @include font-styles(){
        font-family: $overwrite-family, $body-font-family;
    }
}

%secondary-font,
h1,h2,h3,
.lang-code {
    font-family: $header-font-family;

    @include font-styles(){
        font-family: $overwrite-family2, $header-font-family;
    }
}

h1,h2,h3,h4,h5 {
    color: #fefefe;
}