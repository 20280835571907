@include foundation-typography;

@each $break, $styles in $header-styles {
    @include breakpoint($break) {
        @each $style in $styles {
            %#{nth($style, 1)}-size,
            .#{nth($style, 1)}-size {
                font-size: rem-calc(map-get(nth($style, 2), 'font-size'));
            }

            #{nth($style, 1)} + p {
                margin-top: $global-padding * -2/3;
            }
        }
    }

    @each $style in $styles  {
        .#{nth($style, 1)} {
            @extend #{nth($style, 1)};
        }
    }
}

a {
    transition: color $global-transition;
}

blockquote {
    @at-root .quote {
        quotes: '„' '“' '‚' '‘';

        &::before {
            content: open-quote;
        }

        &::after {
            content: close-quote;
        }
    }
}

cite {
    font-style: normal;
}

//helper classes
.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: none;
}

.small {
    font-size: 87.5%;
}

.bold {
    font-weight: bold;
}

.font-normal {
    font-weight: normal;
    font-style: normal;
}

.text-weight-normal {
    font-weight: 400;
}

.normal-lineheight {
    line-height: $global-lineheight;
}

.lineheight-1 {
    line-height: 1;
}

.ultrasmall {
    font-size: rem-calc(11);
}

.inline-list {
    @include menu-simple;

    li {
        font-weight: bold;

        &::before {
            content: '';
            display: inline-block;
            width: rem-calc(10);
            height: rem-calc(10);
            background: $primary-color;
            border-radius: 100%;
            vertical-align: middle;
        }

        &:first-child::before {
            display: none;
        }
    }
}

.madeby {
    color: $white;

    &:hover {
        color: $black;
    }
}