.meat {
    padding-top: rem-calc(100);
    
    .offers {
        .arrow--down {
            width: rem-calc(21);
        }
        
        .arrow--down__path {
            fill: $primary-color;
            stroke: $primary-color;
        }
        
        a:hover {
            .arrow--down__path {
                fill: scale-color($primary-color, $lightness: -20%);
                stroke: scale-color($primary-color, $lightness: -20%);
            }
        }
    }
}

.product__ico {
    width: rem-calc(70);
    margin-bottom: rem-calc(20);
}