@keyframes spin-and-fade {
    0%  {transform: rotate(0deg); opacity: 1;display: block;}
    100%{transform: rotate(360deg); opacity: 0;display: none;}    
}
@keyframes fade-and-spin {
    0%{transform: rotate(360deg); opacity: 0;display: none;}    
    100%  {transform: rotate(0deg); opacity: 1;display: block;}
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-2px);
  }
}

@keyframes horizontal-bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateX(8px);
  }
  60% {
    transform: translateX(4px);
  }
}

@keyframes stretch-and-bounce {
  from, 20%, 30%, 46%, 65%, 78%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, 6px, 0);
  }

  60% {
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, -6px, 0);
  }

  70% {
    transform: translate3d(0,-3px,0);
  }
}

/**
 * Fade-zoom animation for magnific popup
 */

/* start state */
.mfp-zoom-in .mfp-content {
	opacity: 0;
	transition: all 0.2s ease-in-out; 
	transform: scale(0.8); 
}

/* animate in */
.mfp-zoom-in.mfp-ready .mfp-content {
	opacity: 1;
	transform: scale(1); 
}

/* animate out */
.mfp-zoom-in.mfp-removing .mfp-content {
	transform: scale(0.8); 
	opacity: 0;
}

/* Dark overlay, start state */
.mfp-zoom-in.mfp-bg {
	opacity: 0;
	transition: opacity 0.3s ease-out;
}
/* animate in */
.mfp-zoom-in.mfp-ready.mfp-bg {
	opacity: 0.8;
}
/* animate out */
.mfp-zoom-in.mfp-removing.mfp-bg {
	opacity: 0;
}


@keyframes bounce--right {
    
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    
    
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes bounce--down {
    
    0%, 20%, 50%, 80%, 100% {
        transform: translateX(0);
    }
    
    
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0);
    }
}
