$wellDefaultSize: $global-margin !default;

@mixin well($size, $direction, $property) {
    @if (($direction == top) or 
        ($direction == right) or
        ($direction == left) or
        ($direction == bottom)) {
        #{$property}-#{$direction}: #{$size};
    } @else if $direction == horizontal {
        #{$property}-left: #{$size};
        #{$property}-right: #{$size};
    } @else if $direction == vertical {
        #{$property}-top: #{$size};
        #{$property}-bottom: #{$size};
    }
}

// Will create classes like .well-top, .well-horizontal, etc.
@mixin createWells($multiplier: 0, $className:"well", $property:"padding") {
    $size: $wellDefaultSize;
    $postfix: '';

    @if ($multiplier == half) {
        $postfix: '-' + $multiplier;
        $size: .5 * $wellDefaultSize;
        $multiplier: .5;
    } @else if ($multiplier == tiny) {
        $postfix: '-' + $multiplier;
        $size: .25 * $wellDefaultSize;
        $multiplier: .25;
    } @else if ($multiplier > 0) {
        $postfix: '-' + $multiplier + 'x';
        $size: $multiplier * $wellDefaultSize;
    }


    @each $direction in 'left', 'right', 'top', 'bottom', 'vertical', 'horizontal' {
        .#{$className}-#{$direction}#{$postfix} {
            &.#{$className}-#{$direction}#{$postfix} {
                @include well($size, #{$direction}, #{$property});
            }
        }
    }

    .#{$className}#{$postfix} {
        @include well($size, 'vertical', #{$property});
        @include well($size, 'horizontal', #{$property});
    }
}


@include createWells();
@include createWells('half');
@include createWells(2);
@include createWells(3);
@include createWells(0, 'space', 'margin');
@include createWells('half', 'space', 'margin');
@include createWells('tiny', 'space', 'margin');