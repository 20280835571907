body, html {
    height: 100%;
    margin: 0;
}

#logo {
    max-width: rem-calc(459);
    margin-right: 23px;
}

header {
 
    background: url(#{$img-url}/header_masa.png) no-repeat right top;
    background-size: contain;
    
    @include breakpoint(medium down) {
        background: none;
    }
    
    
    
    .row {
        max-width: 820px;
    }
    
    .button {
        width: 222px;
    }



    @include breakpoint(navbreak1 down) {
        .line--long {
            display: none;
        }

        .line--mobile {
            display: inline-block;
            margin-top: rem-calc(40);
        }

        .border--btn {
            float: none !important;
            margin: rem-calc(10);
        }


    }

   

}


 #menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0px;
    z-index: 15;

    ul {
        background: none;
        width: rem-calc(300);

    }    

    li {
        
        &:last-child {
            padding-top: rem-calc(15);
        }
        
        a {
            
            padding-left: rem-calc(50);
            color: $white;
            height: rem-calc(54);
            margin-bottom: 0;
            transition: 300ms ease-out;
            
            p {
                display: flex;
                align-items: center;
                margin: 0;
            }
            
            
            .fi-list {
                
                svg {
                    height: 32px;
                    width: 32px;
                    display: inline-block;
                    margin-right: 20px;
                    transition: 300ms ease-out;
                    
                    .cls-1{fill: $white;}
                    .cls-2{fill: $white;}
                }
            }
            
            .menu--pig {
                svg {
                    .cls-1{fill: none;}
                }
            }
            
            

            .underline {
                margin: 0;
                margin-left: rem-calc(-350);
                margin-top: 0;
                width: 300px;
                transition: 300ms ease-out;
                display: block;
            }
            
            &:hover {
                span {
                    display: inline-block !important;
                    
                }
                
                .underline {
                    margin: 0;
                    margin-left: rem-calc(-50);
                    margin-top: 0;
                    
                }
                
               
                color: scale-color($primary-color, $lightness: -20%);
                
                .fi-list {
                    svg {
                        .cls-1{fill: scale-color($primary-color, $lightness: -20%);}
                        .cls-2{fill: scale-color($primary-color, $lightness: -20%);}
                    }
                }
                
                .menu--pig {
                    svg {
                        .cls-1{fill: none;}
                    }
                }
                

            }


        }
    }
    
    @include breakpoint(medium down) {
        
        
        
        width: 100%;
        height: 100vh;
        background: $primary-color;
        display: none;

        .vertical.menu {
            width: 100%;
            height: 100%;
            margin-top: 100px;
            
        }
        
        .underline {
            display: none;
        }
        
        li {
            a {
                color: $black;
                padding-left: 0.6875rem;
                
                p {
                    justify-content: center;
                }
                
                .fi-list {
                    svg {
                        .cls-1{fill: $black}
                        .cls-2{fill: $black}
                    }
                }
                
                .menu--pig {
                    svg {
                        .cls-1{fill: none;}
                    }
                }
                
                &:hover {
                    background: rgba(200,152,69,0.5);
                    color: $black;
                    
                    .underline {
                        display: none;
                    }
                    
                    .fi-list {
                        svg {
                            .cls-1{fill: $black}
                            .cls-2{fill: $black}
                        }
                    }
                    
                    .menu--pig {
                        svg {
                            .cls-1{fill: none;}
                        }
                    }
                }
            }
        }
        
        
        
        
    }
}

#hamburger {
    z-index: 99;
    position: fixed;
    right: 10px;
    display: none;
    top: 10px;
    font-size: 44px;
    color: #fff;
    cursor: pointer;
    
    img {
        width: rem-calc(44);
        height: rem-calc(44);
    }

    .icon {
        display: block;
    }
    .icon--open {
        background: url(#{$svg-url}/menuburger.svg) no-repeat center;
        width: rem-calc(40);
        height: rem-calc(40);
    }

    .icon--close {
        background: url(#{$svg-url}/zpet.svg) no-repeat center;
        width: rem-calc(40);
        height: rem-calc(40);
    }    
    
    @include breakpoint(medium down) {
        & {
            display: block;
        }
    }
}

.header--page {
    background: none;
    padding: rem-calc(15);
    justify-content: space-between;
    margin-bottom: rem-calc(25);
    font-size: 1.125rem;
    color: $primary-color;
    
    .media-object {
        justify-content: space-between;
        align-items: center;
    }
    
    #back--page {
        display: none;
    }
    
    
    .section--back {
        color: transparent;
        display: flex;
        align-items: center;
        
        &:hover {
            color: transparent;
        }
    }
    
    &.row--extended {
        width: 100%;
    }
    
    .tel {
        width: rem-calc(32);
    }
    
    #logo--page {
        width: rem-calc(105);
    }
    
    p {
        margin-bottom: 0;
    }
    
    a {
        color: $primary-color;;
        
        .menu--jr {
            svg {
                height: rem-calc(32);
                width: rem-calc(32);
                display: inline-block;
                margin-right: 20px;

                .cls-1 {
                    fill: $primary-color;;
                }
            }
        }
        
        &:hover {
            color: $primary-color;

            svg {
                .cls-1 {
                    fill: $primary-color;
                }
            }
        }
    }
    
    @include breakpoint(medium down) {

        position: fixed;
        background: #1C1C1C;
        
        
        #logo--page {
            display: none;
        }
        
        #back--page {
            display: block;
            width: rem-calc(20);
            margin-right: rem-calc(15);
        }
        
        .section--back {
            color: $primary-color;
            
            &:hover {
                color: $primary-color;
            }
        }
        
        p {
            margin-bottom: 0;
        }
        
    }
    
}

  



