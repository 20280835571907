/**
 * WooCommerce
 */

// TODO: make it better (try hovering cart really fast)
@keyframes cart-in {
    0% {
        left: -999em;
    }
    0.001% {
        opacity: 0;
        right: auto;
        transform: translateX(20%);
    }
    100% {
        opacity: 1;
        right: 0;
        left: auto;
        transform: translateX(0);
    }
}

@keyframes cart-out {
    0% {
        opacity: 1;
        right: 0;
        left: auto;
        transform: translateX(0);
    }
    99.9999% {
        opacity: 0;
        right: auto;
        transform: translateX(20%);
    }
    100% {
        left: -999em;
    }
}

body {
    overflow-x: hidden;
    overflow-y: visible;
}

.site-header-cart {
    list-style-type: none;
    margin-left: 0;
    margin-bottom: 0;

    @include breakpoint(medium) {
        position: relative;

        &:hover,
        &.focus {
            .widget_shopping_cart {
                left: auto;
                right: 0;
                display: block;
                animation-name: cart-in;
                animation-duration: .1s;
                animation-iteration-count: 1;
            }
        }

        .widget_shopping_cart {
            // @include mui-animation(fade);
            animation-name: cart-out;
            animation-duration: .1s;
            animation-iteration-count: 1;
        }
    }

    .cart-contents {
        display: none;

        @include breakpoint(medium) {
            padding: ($global-padding/4) 0;
            display: block;
            position: relative;
            background-color: transparent;
            height: auto;
            width: auto;
            text-indent: 0;

            .amount {
                margin-right: .327em;
            }

            .count {
                font-weight: 300;
                opacity: 0.5;
            }

            span {
                display: inline;
            }
        }
    }

    .widget_shopping_cart {
        display: none;

        @include breakpoint(medium) {
            position: absolute;
            padding: $global-padding;
            top: 100%;
            width: rem-calc(300);
            margin-top: $global-padding * -1;
            margin-right: $global-padding * -1;
            z-index: 999999;
            left: -999em;
            display: block;

            &.sub-menu--is-touch-device {
                display: none;
                left: 0;
            }

            h2.widgettitle {
                display: none;
            }
            
            // .product_list_widget li a.remove {
            //     position: relative;
            //     float: left;
            //     top: auto;
            //     &:before {
            //         text-align: left;
            //     }
            // }
        }

        .woocommerce-mini-cart__empty-message {
            margin: ms(2);
        }
        .product_list_widget {
            img {
                margin-left: 1em;
            }
        }

        .widget_shopping_cart_content {
            padding: $global-padding;
            background: $white;
        }
    }
}

.site-search {
    clear: both;
    display: none;

    @include breakpoint(medium) {
        display: block;

        form {
            margin: 0;
        }
    }

    .widget_product_search {
        input[type=text],
        input[type=search] {
            padding: ms(1) ms(2);
            line-height: 1;
        }

        form {
            &:before {
                top: 1.15em;
                left: 1.15em;
            }
        }

        #searchsubmit {
            @include element-invisible;
        }
    }
}


/**
 * Shop tables
 */
table.shop_table_responsive {
    thead {
        display: none;

        @include breakpoint(medium) {
            display: table-header-group;
        }
    }

    tbody {
        th {
            display: none;

            @include breakpoint(medium) {
                display: table-cell;
            }
        }
    }

    tr {
        td {
            display: block;
            text-align: right;
            clear: both;

            @include breakpoint(medium) {
                display: table-cell;
            }

            &:before {
                content: attr(data-title) ': ';
                font-weight: 600;
                float: left;

                @include breakpoint(medium) {
                    display: none;
                }
            }

            &.product-remove {
                a {
                    text-align: left;
                }

                &:before {
                    display: none;
                }
            }

            &.actions,
            &.download-actions {
                &:before {
                    display: none;
                }
            }

            &.download-actions {
                .button {
                    display: block;
                    text-align: center;
                }
            }
        }
    }

    &.my_account_orders {
        .order-actions {
            text-align: right;

            &:before {
                display: none;
            }
        }
    }
}

/**
 * Products
 */
ul.products {
    margin-left: 0;
    margin-bottom: 0;
    clear: both;
    @include clearfix;

    li.product {
        list-style: none;
        margin-left: 0;
        margin-bottom: ms(7);
        text-align: center;
        position: relative;

        .star-rating {
            margin: 0 auto ms(-3);
        }

        .woocommerce-LoopProduct-link {
            display: block;
        }

        .price {
            display: block;
            color: $black;
            font-weight: 400;
            margin-bottom: 1rem;

            ins {
                margin-left: ms(-2);
                background-color: transparent;
            }
        }

        h2, // @todo Remove when WooCommerce 2.8 is live
        h3, // @todo Remove when WooCommerce 2.8 is live
        .woocommerce-loop-product__title {
            font-size: 1rem;
            font-weight: 400;
            margin-bottom: ms(-3);
        }

        .star-rating {
            font-size: ms(-1);
        }

        img {
            display: block;
            margin: 0 auto ms(3);
        }

        &.product-category {
            h2, // @todo Remove when WooCommerce 2.8 is live
            h3, // @todo Remove when WooCommerce 2.8 is live
            .woocommerce-loop-category__title {
                font-size: 1.1em;
            }

            img {
                margin-bottom: ms(3);
            }
        }

        .button {
            margin-bottom: .236em;
        }
    }
}

.price {
    del {
        opacity: .5;
        font-weight: 400;

        & + ins {
            margin-left: .327em;
        }
    }
}

/**
 * Single Product
 */
.single-product {
    .pswp__button {
        background-color: transparent;
    }

    div.product {
        @include clearfix;
        position: relative;
        overflow: hidden;

        .images,
        .summary,
        .woocommerce-product-gallery {
            margin-bottom: ms(5);
            margin-top: 0;
        }

        .woocommerce-product-gallery {
            position: relative;

            .zoomImg {
                background-color: #fff;
            }

            .woocommerce-product-gallery__trigger {
                position: absolute;
                top: ms(-1);
                right: ms(-1);
                display: block;
                height: 2em;
                width: 2em;
                border-radius: 3px;
                z-index: 99;
                text-align: center;
            }

            img {
                margin: 0;
            }

            .flex-viewport {
                margin-bottom: ms(3);
            }

            .flex-control-thumbs {
                @include clearfix;
                margin: 0;
                padding: 0;

                li {
                    list-style: none;
                    margin-bottom: ms(3);
                    cursor: pointer;

                    img {
                        opacity: .5;
                        transition: all, ease, .2s;

                        &.flex-active {
                            opacity: 1;
                        }
                    }

                    &:hover {
                        img {
                            opacity: 1;
                        }
                    }
                }
            }

            &.woocommerce-product-gallery--columns-2 {
                .flex-control-thumbs {
                    li {
                        @include grid-col(6);

                        &:nth-child(2n) {
                            margin-right: 0;
                        }

                        &:nth-child(2n+1) {
                            clear: both;
                        }
                    }
                }
            }

            &.woocommerce-product-gallery--columns-3 {
                .flex-control-thumbs {
                    li {
                        @include grid-col( 1.333333333 of 4 );

                        &:nth-child(3n) {
                            margin-right: 0;
                        }

                        &:nth-child(3n+1) {
                            clear: both;
                        }
                    }
                }
            }

            &.woocommerce-product-gallery--columns-4 {
                .flex-control-thumbs {
                    li {
                        @include grid-col( 1 of 4);

                        &:nth-child(4n) {
                            margin-right: 0;
                        }

                        &:nth-child(4n+1) {
                            clear: both;
                        }
                    }
                }
            }

            &.woocommerce-product-gallery--columns-5 {
                .flex-control-thumbs {
                    li {
                        @include grid-col( .8 of 4);

                        &:nth-child(5n) {
                            margin-right: 0;
                        }

                        &:nth-child(5n+1) {
                            clear: both;
                        }
                    }
                }
            }
        }

        .images {
            .woocommerce-main-image {
                margin-bottom: ms(3);
                display: block;
            }

            .thumbnails {
                a.zoom {
                    display: block;
                    width: 22.05%;
                    margin-right: 3.8%;
                    float: left;
                    margin-bottom: 1em;

                    &.last {
                        margin-right: 0;
                    }

                    &.first {
                        clear: both;
                    }
                }
            }
        }

        form.cart {
            @include clearfix;
            margin-bottom: ms(3);
            padding: 1em 0;

            .quantity {
                float: left;
                margin-right: ms(-1);
            }
        }

        p.price {
            font-size: ms(2);
            margin: ms(2) 0;
        }

        table.variations {
            margin: 0;

            th,
            td {
                display: list-item;
                padding: 0;
                list-style: none;
                background-color: transparent;
            }

            .value {
                margin-bottom: 1em;
            }
        }

        .single_variation {
            .price {
                margin-bottom: 1em;
                display: block;
            }
        }

        .variations_button {
            @include clearfix;
            padding-top: 1em;
        }

        .woocommerce-product-rating {
            @include clearfix;

            .star-rating {
                float: left;
            }
        }

        .product_meta {
            padding-top: 1em;

            .sku_wrapper,
            .posted_in,
            .tagged_as {
                display: block;

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
}

.stock {
    &:empty:before {
        display: none;
    }

    &.in-stock {
        color: get-color(success);
    }

    &.out-of-stock {
        color : get-color(alert);
    }
}

a.reset_variations {
    display: inline-block;
    margin-left: 1em;
}


/**
 * Breadcrumbs
 */
.woocommerce-breadcrumb {
    margin-left: -1.387rem;
    margin-right: -1.387rem;
    margin-bottom: 1rem;
    padding: 1em 1.387rem;

    .breadcrumb-separator {
        display: inline-block;
    }
}

/**
 * Widgets
 */
.widget_shopping_cart {
    .product_list_widget {
        margin-bottom: 0;

        // li {
        //     padding-left: 2em;
        //     position: relative;

        //     a.remove {
        //         position: absolute;
        //         top: 1em;
        //         left: 0;
        //         opacity: 1;
        //     }
        // }
    }

    p.total,
    p.buttons {
        padding: 1em 0;
        margin: 0;
        text-align: center;
    }

    p.total {
        border-bottom: 1px solid $black;
    }

    .buttons {
        .button {
            display: block;

            &:nth-child(odd) {
                margin-bottom: ms(-2);
            }
        }
    }
}


/**
 * Cart
 */
table.cart {
    .product-quantity .plus,
    .product-quantity .minus {
        display: none;
    }

    .product-thumbnail {
        img {
            margin: 0 auto;
            max-width: ms(6);
            height: auto;
        }

        &:before {
            display: none;
        }
    }

    td.product-remove {
        @include clearfix;
        padding: 0;
        position: relative;

        a.remove {
            position: absolute;
            font-size: 1.41575em;
            top: 1.41575em * 1/2;
            right: 1.41575em * 1/2;
            text-align: center;
        }
    }

    td.product-quantity {
        .qty {
            padding: .326em;
            width: ms(6);
        }
    }

    td,
    th {
        padding: ms(-1) ms(-1) 0;
    }

    td.product-subtotal {
        padding-bottom: ms(-1);
    }

    td.actions {
        border-top: 1em solid;
        background-color: transparent;
        padding: 0;

        label {
            display: none;
        }

        .coupon {
            padding-bottom: 1em;
            margin-bottom: 1em;
            border-bottom: 1px solid $black;
        }

        input {
            display: block;
            width: 100%;
            margin: ms(-3) 0;

            &[name="update_cart"] {
                &:focus {
                    outline: none;
                }
            }
        }
    }
}

/**
 * Checkout
 *
 * 1 - Required to make the blockUI overlay cover the entire page rather than just the checkout form. We do this because
 *     otherwise our sticky order review can break out of the checkout form (and the blockUI element).
 */
.checkout_coupon {
    margin-bottom: $global-padding * 5;
}

form.checkout {
    @include clearfix;
    position: static !important; /* 1 */

    > .blockUI {
        position: fixed !important; /* 1 */
    }
}

#payment {
    @include clearfix;

    .create-account {
        clear: both;
    }

    .payment_methods {
        margin: 0 !important;
        padding: 0 !important;

        > .wc_payment_method {
            > label {
                display: block;
                padding: $global-padding * 2;
                cursor: pointer;
            }
        }

        li {
            list-style: none !important;
            margin-left: 0;
            position: relative;

            img {
                float: right;
                border: 0;
                padding: 0;
                max-height: $global-padding * 2;
            }

            &:last-child:not(.woocommerce-notice) {
                padding-bottom: 0;
                border-bottom: 0;
            }

            p {
                margin: 0;
            }

            .payment_box {
                padding: ms(2);

                fieldset {
                    border: 0;
                    margin: 0;
                    padding: 1em 0 0 0;

                    label {
                        width: auto;
                        margin-left: 0;
                    }

                    .form-row {
                        margin-bottom: 1em;

                        input[type="checkbox"] {
                            margin-right: 5px;
                        }
                    }

                    #cvv {
                        width: 22% !important;
                    }

                    .help {
                        width: 75%;
                        float: right;
                    }
                }

                .woocommerce-SavedPaymentMethods {
                    padding-top: 1em;
                    margin: 0;

                    li {
                        input[type=radio] {
                            margin-right: .236em;
                        }
                    }
                }

                ul.wc-saved-payment-methods {
                    margin-left: 0;
                }

                .wc-credit-card-form-card-number,
                .wc-credit-card-form-card-expiry,
                .wc-credit-card-form-card-cvc {
                    font-size: $global-padding * 2;
                }
            }
        }
    }

    .terms {
        width: 100%;
        clear: both;
        float: none;
        margin-bottom: 0;
        margin-bottom: 1em;

        label {
            display: block;
            cursor: pointer;
        }
    }

    .woocommerce-terms-and-conditions {
        padding: $global-padding;
        box-shadow: inset 0 1px 3px rgba(#000,.2);
        margin-bottom: 16px;
        background-color: rgba(#000,.05);
    }

    .place-order {
        padding: $global-padding * 2;
        margin-bottom: 0;
        float: none;
        width: 100%;
        margin-top: $global-padding * 5;

        .button {
            font-size: $global-padding * 2;
            width: 100%;
            white-space: pre-wrap;
        }
    }
}

table.woocommerce-checkout-review-order-table {
    .product-name {
        width: 300px;
        word-wrap: break-word;
    }
}

.woocommerce-checkout {
    .payment-fixed {
        position: fixed;
        top: 0;
        z-index: 9;
        box-shadow: 0 6px 2em rgba(#000,.2);
    }
}

.admin-bar {
    .woocommerce-checkout {
        .payment-fixed {
            top: 32px;
        }

        #wc_checkout_add_ons label + br {
            display: none;
        }
    }
}

/**
 * Password strength meter
 */
.woocommerce-password-strength {
    text-align: left;
    font-weight: 600;
    padding: 0 0;
    text-align: right;

    // &.strong {
    //     color: get-color(success);
    // }

    // &.short {
    //     color: get-color(alert);
    // }

    // &.bad {
    //     color: get-color(alert);
    // }

    // &.good {
    //     color: get-color(warning);
    // }
}


/**
 * General WooCommerce components
 */

.woocommerce-form__label-for-checkbox {
    cursor: pointer;
    display: block;
}

.form-row {
    label {
        display: block;
    }

    input,
    textarea,
    select {
        width: 100%;
    }

    input[type=checkbox],
    input[type=radio] {
        width: auto;
    }

    .button {
        width: auto;
    }

    &.create-account {
        label {
            display: inline-block;
        }
    }

    &.woocommerce-validated {
        input.input-text {
            box-shadow: inset 2px 0 0 get-color(success);
        }
    }

    &.woocommerce-invalid {
        input.input-text {
            box-shadow: inset 2px 0 0 get-color(alert);
        }
    }
}

.form-row-last {
    margin-right: 0 !important;
}

.form-row-wide {
    clear: both;
    width: 100%;
}

.required {
    border-bottom: 0 !important;
    color: red;
}

.onsale {
    border: 1px solid;
    border-color: $black;
    color: $black;
    padding: .202em ms(-2);
    font-size: ms(-1);
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 1em;
    border-radius: 3px;
}

.quantity {
    .qty {
        width: $global-padding * 10;
        text-align: center;
    }
}

.woocommerce-tabs {
    padding: 1em 0;

    ul.tabs {
        list-style: none;
        margin-left: 0;
        text-align: left;
        border-top: 1px solid $black;

        li {
            display: block;
            margin: 0;
            border-bottom: 1px solid $black;
            position: relative;

            a {
                padding: 1em 0;
                display: block;
            }
        }
    }

    .panel {
        h2:first-of-type {
            margin-bottom: 1em;
        }
    }
}

.related,
.upsells {
    > h2:first-child {
        margin-bottom: 1em;
    }
}

.woocommerce-message,
.woocommerce-info,
.woocommerce-error,
.woocommerce-noreviews,
p.no-comments {
    @include clearfix;
    background-color: get-color(success);
    margin-left: 0;
    border-radius: 2px;
    color: #fff;
    clear: both;

    a {
        color: #fff;

        &:hover {
            color: #fff;
            opacity: 0.7;
        }

        &.button:hover {
            opacity: 1;
        }
    }

    .button {
        float: right;
        padding: 0;
        background: none;
        color: #fff;
        box-shadow: none;
        line-height: 1.618;
        padding-left: 1em;
        border-width: 0;
        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: rgba(255,255,255,0.25) !important;
        border-radius: 0;

        &:hover {
            background: none;
            color: #fff;
            opacity: 0.7;
        }
    }

    pre {
        background-color: rgba(0,0,0,.1);
    }
}

.site-content {
    > .col-full {
        > .woocommerce {
            > .woocommerce-message,
            > .woocommerce-info,
            > .woocommerce-error {
                &:first-child {
                    margin-top: $global-padding * 3;
                }
            }
        }
    }
}

.woocommerce-error {
    list-style: none;
}

.woocommerce-info,
.woocommerce-noreviews,
p.no-comments {
    background-color: get-color(tertiary);
}

.woocommerce-error {
    background-color: get-color(alert);
}

dl.variation {
    dd {
        margin: 0 0 1em;
    }
}

.wc-item-meta {
    margin-left: 0;
    list-style: none;

    li {
        @include clearfix;

        strong,
        p {
            display: inline-block;
        }
    }
}

.woocommerce .widget_shopping_cart .cart_list li,
.woocommerce.widget_shopping_cart .cart_list li {
}

/**
 * My Account
 */
.woocommerce-MyAccount-content {
    .woocommerce-Pagination {
        text-align: center;
    }
}