@media print {
    body {
        font-size: 87.5%;
    }

    article {
        page-break-inside: avoid;
    }

    .print-block {
        display: block!important;
    }

    .print-hide-url {
        &::after {
            display: none!important;
        }
    }

    .print-page-break {
        page-break-before: always;
    }

    .print-avoid-page-break {
        page-break-inside: avoid;
    }

    .print-border {
        // border-top: 1px solid $dark-gray;
        border-bottom: 1px solid $dark-gray;
    }

    //
    // Fixes
    //

    .sticky {
        position: static !important;
    }

    .sticky-container {
        height: auto!important;
    }
}
