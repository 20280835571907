.description {
    color: $primary-color;
    font-size: rem-calc(15);
}

.talk-about-us {
    .row {
        article {
            padding: 0 rem-calc(50);
        }
        
        @include breakpoint(1024 down) {
            article:nth-last-child(1):nth-child(odd) {
                float: none;
                clear: both;
                margin: 0 auto;
            }
        }
    }
    
    h3 {
        margin: rem-calc(30) 0 rem-calc(40);
    }
}

.contact-us {

    h2 {
        font-size: rem-calc(21);
    }
    
    .line--long {
        height: rem-calc(200);
    }
    
    .button {
        width: rem-calc(400);
        font-size: rem-calc(28);
        
        @include breakpoint(small-land) {
            width: rem-calc(350)
        }
        
        @include breakpoint(small) {
            width: rem-calc(250)
        }
    }
}

.contact {
    
    
    
    .row {
        img {
            margin: $global-margin;
        }

        max-width: 820px;
        
    } 
    
    .small {
        font-size: rem-calc(21);
        font-weight: bold;
        color: $white;
    }
    
    p {
        color: $primary-color;
        font-size: rem-calc(24);
        font-weight: bold;
        line-height: 1.25;
        
        span {
            font-size: rem-calc(18);
            color: $white;
        }
    }
    
    .name {
        font-size: rem-calc(36);
    }
    
    .media-object-section {
        flex: 1 1 auto;
    }
    
    @include breakpoint(716 down) {
        .media-object-section:nth-child(2) {
            display: none;
        }
    }
}