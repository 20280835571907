.sos {
    
    .text-left {
        margin-left: 15px;
    }
    
    .sos__text {
        max-width: 700px;
        margin: 0 auto;
    }
    
    #sos__img {
        position: relative;
        bottom: -90px;
        right: -20px;
        
        img {
            width: 120%;
        }
        
    }
    
    .row--extended {
        padding-left: 0;
        margin-left: 0;
        max-width: 112.5rem;
    }
    
    @include breakpoint(1200 down) {
        #sos__img {
            bottom: -120px;
        }
        
    }
    
    @include breakpoint(1100 down) {
        .sos__img-box {
            margin-left: auto;
        }
        
        #sos__img {
            bottom: -30px;
        }
        
        .media-object {
            flex-wrap: wrap;
        }
    }
    
    @include breakpoint(630 down) {
        #sos__img {
            right: 30px;
        }
    }
    
}