$img-url: "../img";
$svg-url: "../svg";

$global-transition: .25s ease-out;

// Three stripes decoration
$decor-height: 6px;
$decor-colors: (get-color(secondary), get-color(primary), get-color(tertiary));

$topbar-shadow: 0 2px 5px rgba(#000, .1);

// Gap around content
$content-gap: null;

$footer-height: rem-calc(80);
$header-height: rem-calc(120);