/* --------------------------------

Modules - reusable parts of our design

-------------------------------- */

.cd-container {
  width: 90%;
  max-width: 3em; // breakpoints inside partials > _layout.scss
  margin: 2em auto;

  &::after { /* clearfix */
    content: '';
    display: table;
    clear: both;
  }
}

@keyframes back-border {
    0% {
        border-radius: 100% 100% 0 100%;
    }

    100% {
        border-radius: 100% 0  100% 100%;
    }
}

/* --------------------------------

Main components

-------------------------------- */

.cd-top {
    height: 40px;
    width: 40px;
    background: $white;
    border-radius: 0;

    position: fixed;
    z-index: 666;
    bottom: 40px;
    left: 20px;
    text-align: center;

    // box-shadow: 0 0 10px rgba(#000, .05);

    /* image replacement properties */
    overflow: hidden;
    // text-indent: 100%;
    white-space: nowrap;

    background-color: rgba($secondary-color, 1);
    border: 1px solid rgba($lightest-gray, 0);

    visibility: hidden;
    opacity: 0;

    transition: all $global-transition;

    &.cd-is-visible, &.cd-fade-out, &:hover {
        transition: all $global-transition;
    }

    &.cd-is-visible { /* the button becomes visible */
        visibility: visible;
        opacity: 1;
    }

    &.cd-fade-out { /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
        // opacity: .5;
        background-color: rgba($secondary-color, .4);
        border-color: $secondary-color;
    }

    &:hover {
        background-color: rgba(get-color(secondary), 1);
        border-color: get-color(secondary);
        opacity: 1;
        cursor: pointer;
    }

    &:active {
        // border-radius: 100% 0  100% 100%;
        // animation: back-border 1s;
    }

    @include breakpoint(large) {
        height: rem-calc(40);
        width: rem-calc(40);
        bottom: rem-calc(24);
    }

    &::before {
        content: '';
        @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="#{$white}" viewBox="0 0 32 32"><polygon points="0,28 32,28 16,5"/></svg>');
        width: 14px;
        height: 14px;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: -40%;
    }

    // &:hover::before {
    //     @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" stroke="#{$white}" stroke-width="4px" viewBox="0 0 32 32"><path d="M6 10 L16 2 26 10 M16 2 L16 30"/></svg>');
    // }
}