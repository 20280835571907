$loader-color: $medium-gray;

@mixin loader() {
    margin: auto;
    font-size: 5px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: load5 .9s infinite ease;
    transform: translateZ(0);
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

%loader {
    @include loader();
}

@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em $loader-color, 1.8em -1.8em 0 0em rgba($loader-color, 0.2), 2.5em 0em 0 0em rgba($loader-color, 0.2), 1.75em 1.75em 0 0em rgba($loader-color, 0.2), 0em 2.5em 0 0em rgba($loader-color, 0.2), -1.8em 1.8em 0 0em rgba($loader-color, 0.2), -2.6em 0em 0 0em rgba($loader-color, 0.5), -1.8em -1.8em 0 0em rgba($loader-color, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($loader-color, 0.7), 1.8em -1.8em 0 0em $loader-color, 2.5em 0em 0 0em rgba($loader-color, 0.2), 1.75em 1.75em 0 0em rgba($loader-color, 0.2), 0em 2.5em 0 0em rgba($loader-color, 0.2), -1.8em 1.8em 0 0em rgba($loader-color, 0.2), -2.6em 0em 0 0em rgba($loader-color, 0.2), -1.8em -1.8em 0 0em rgba($loader-color, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba($loader-color, 0.5), 1.8em -1.8em 0 0em rgba($loader-color, 0.7), 2.5em 0em 0 0em $loader-color, 1.75em 1.75em 0 0em rgba($loader-color, 0.2), 0em 2.5em 0 0em rgba($loader-color, 0.2), -1.8em 1.8em 0 0em rgba($loader-color, 0.2), -2.6em 0em 0 0em rgba($loader-color, 0.2), -1.8em -1.8em 0 0em rgba($loader-color, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($loader-color, 0.2), 1.8em -1.8em 0 0em rgba($loader-color, 0.5), 2.5em 0em 0 0em rgba($loader-color, 0.7), 1.75em 1.75em 0 0em $loader-color, 0em 2.5em 0 0em rgba($loader-color, 0.2), -1.8em 1.8em 0 0em rgba($loader-color, 0.2), -2.6em 0em 0 0em rgba($loader-color, 0.2), -1.8em -1.8em 0 0em rgba($loader-color, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba($loader-color, 0.2), 1.8em -1.8em 0 0em rgba($loader-color, 0.2), 2.5em 0em 0 0em rgba($loader-color, 0.5), 1.75em 1.75em 0 0em rgba($loader-color, 0.7), 0em 2.5em 0 0em $loader-color, -1.8em 1.8em 0 0em rgba($loader-color, 0.2), -2.6em 0em 0 0em rgba($loader-color, 0.2), -1.8em -1.8em 0 0em rgba($loader-color, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($loader-color, 0.2), 1.8em -1.8em 0 0em rgba($loader-color, 0.2), 2.5em 0em 0 0em rgba($loader-color, 0.2), 1.75em 1.75em 0 0em rgba($loader-color, 0.5), 0em 2.5em 0 0em rgba($loader-color, 0.7), -1.8em 1.8em 0 0em $loader-color, -2.6em 0em 0 0em rgba($loader-color, 0.2), -1.8em -1.8em 0 0em rgba($loader-color, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba($loader-color, 0.2), 1.8em -1.8em 0 0em rgba($loader-color, 0.2), 2.5em 0em 0 0em rgba($loader-color, 0.2), 1.75em 1.75em 0 0em rgba($loader-color, 0.2), 0em 2.5em 0 0em rgba($loader-color, 0.5), -1.8em 1.8em 0 0em rgba($loader-color, 0.7), -2.6em 0em 0 0em $loader-color, -1.8em -1.8em 0 0em rgba($loader-color, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($loader-color, 0.2), 1.8em -1.8em 0 0em rgba($loader-color, 0.2), 2.5em 0em 0 0em rgba($loader-color, 0.2), 1.75em 1.75em 0 0em rgba($loader-color, 0.2), 0em 2.5em 0 0em rgba($loader-color, 0.2), -1.8em 1.8em 0 0em rgba($loader-color, 0.5), -2.6em 0em 0 0em rgba($loader-color, 0.7), -1.8em -1.8em 0 0em $loader-color;
  }
}