@include foundation-grid;

.row {
    @each $break, $value in $grid-column-gutter {
        // @if ($break == small) {
        //     $break: 480px;
        // }

        @include breakpoint($break) {
            padding-left: rem-calc($value);
            padding-right: rem-calc($value);

            &.column {
                padding-left: rem-calc($value * 1.5);
                padding-right: rem-calc($value * 1.5);
            }
        }
    }

    .row {
        padding-left: 0;
        padding-right: 0;
    }

    &.column {
        margin-left: auto;
        margin-right: auto;
    }
}
